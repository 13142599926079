import { ErrorCodes } from '~/api/global/errors/codes/codes';
import type { IError } from '~/repository/extensions/error/error.types';
import { useAlertStore } from '~/store/alert/alert.store';

export const informOnError = (e: IError) => {
  const alertStore = useAlertStore();
  alertStore.showError({
    title: Object.values(ErrorCodes).includes(e.key) ? e.key : ErrorCodes.UNPREDICTED_EXCEPTION,
  });
};
